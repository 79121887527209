import { render, staticRenderFns } from "./MobileServiceMobile.vue?vue&type=template&id=3f27dc96&scoped=true&"
import script from "./MobileServiceMobile.vue?vue&type=script&lang=ts&"
export * from "./MobileServiceMobile.vue?vue&type=script&lang=ts&"
import style0 from "./MobileServiceMobile.vue?vue&type=style&index=0&id=3f27dc96&scoped=true&lang=css&"
import style1 from "./MobileServiceMobile.vue?vue&type=style&index=1&id=3f27dc96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f27dc96",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VDialog,VIcon,VImg,VLayout,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f27dc96')) {
      api.createRecord('3f27dc96', component.options)
    } else {
      api.reload('3f27dc96', component.options)
    }
    module.hot.accept("./MobileServiceMobile.vue?vue&type=template&id=3f27dc96&scoped=true&", function () {
      api.rerender('3f27dc96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MobileServiceMobile.vue"
export default component.exports