var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "card-mechanical-repair-container" } },
    [
      _c(
        "v-row",
        { staticClass: "pb-5", attrs: { id: "row-diagnosis-container" } },
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-sm-10 col-md-8 pb-0 pb-md-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-sm-row py-5 align-items-center",
                },
                [
                  _c("v-img", {
                    staticClass: "px-6 mx-auto mx-md-0 shrink",
                    attrs: {
                      contain: "",
                      width: "150",
                      src: require("../assets/icons/mechanicalRepair/mechanical-repairs.svg"),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-center text-center text-md-left",
                    },
                    [
                      _c("h3", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("MechanicalRepairMore.Title"))),
                      ]),
                      _c("span", { staticClass: "information d-block" }, [
                        _vm._v(_vm._s(_vm.$t("MechanicalRepairMore.SubTitle"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "col-12 col-md-4 mt-0 mt-md-3 pt-0 pt-md-3 align-self-center text-center text-md-end",
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none white--text mr-md-9 font-weight-400",
                  attrs: {
                    color: "#12945F",
                    width: "242",
                    height: "48",
                    to: { name: "AutoEstimate" },
                    elevation: "0",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GetAnEstimate")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }