var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-block d-sm-none" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "md-col-11" }, [
            _c("h4", { staticClass: "mb-0 mx-6 service-header text-none" }, [
              _vm._v(_vm._s(_vm.$t("PopularServices"))),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  { staticClass: "col-md-12 mx-0" },
                  [
                    _c(
                      "v-col",
                      { class: ["col-12"] },
                      [
                        _c(
                          "v-list",
                          _vm._l(
                            _vm.mechanicalRepairServices,
                            function (service, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  class: [
                                    "list-border",
                                    index === 3
                                      ? "last-item-border-bottom"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      key: index,
                                      staticClass:
                                        "d-flex w-100 text-decoration-none text--black px-3",
                                      attrs: {
                                        to: {
                                          name: "AutoEstimate",
                                          params: {
                                            categoryId:
                                              service.params.categoryId,
                                            serviceName: _vm.codePrettyUrl(
                                              service.params.serviceName
                                            ),
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  width: "40",
                                                  height: "38",
                                                  src: require("../assets/icons/mechanicalRepair/" +
                                                    service.pathImage),
                                                },
                                              }),
                                              _c("v-list-item-title", {
                                                staticClass:
                                                  "ml-2 text-color-black",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    service.description
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "grey" } },
                                            [_vm._v(" mdi-chevron-right ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "md-col-11 " }, [
            _c("h4", { staticClass: "mb-0 mx-6 service-header" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("CarRepairAndMaintainService.OtherServices.Title")
                  )
              ),
            ]),
            _c("p", { staticClass: "information px-6 my-0" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("CarRepairAndMaintainService.OtherServices.SubTitle")
                )
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  { staticClass: "col-md-12 mx-0" },
                  [
                    _c(
                      "v-col",
                      { class: ["col-12"] },
                      [
                        _c(
                          "v-list",
                          _vm._l(_vm.services, function (service, index) {
                            return _c(
                              "v-list-item",
                              {
                                key: index,
                                class: [
                                  "list-border",
                                  index === 6 ? "last-item-border-bottom" : "",
                                ],
                                attrs: { "active-class": "pink--text" },
                              },
                              [
                                index !== 7 && index !== 8 && index !== 9
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "w-100 px-3",
                                          on: {
                                            click: function ($event) {
                                              _vm.comingSoonServices.includes(
                                                service.description
                                              )
                                                ? (_vm.comingSoonDialog = true)
                                                : null
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "d-flex text-decoration-none text--black",
                                              attrs: {
                                                event:
                                                  !_vm.comingSoonServices.includes(
                                                    service.description
                                                  )
                                                    ? "click"
                                                    : "",
                                                to: {
                                                  name: service.goTo,
                                                  params: service.params,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          width: "40",
                                                          height: "38",
                                                          src: require("../assets/icons/otherServices/" +
                                                            service.pathImage),
                                                        },
                                                      }),
                                                      _c("v-list-item-title", {
                                                        staticClass:
                                                          "ml-2 text-color-black",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            service.description
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "grey" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-chevron-right "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "384" },
          model: {
            value: _vm.comingSoonDialog,
            callback: function ($$v) {
              _vm.comingSoonDialog = $$v
            },
            expression: "comingSoonDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-2 pb-8 px-6" },
            [
              _c(
                "v-card-title",
                { staticClass: "w-100 justify-space-between px-0" },
                [
                  _c("p", { staticClass: "text-center font-weight-regular" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Information")) + " "),
                  ]),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.comingSoonDialog = false
                        },
                      },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "px-0 py-0 title black--text font-weight-medium",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ComingSoon")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }