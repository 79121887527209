var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "mobile-service-container" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "col-md-11 mx-auto" }, [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "h2",
                {
                  staticClass:
                    "title-section header-title text-center mx-auto mb-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("CarRepairAndMaintainService.SectionTitle")
                      ) +
                      " "
                  ),
                ]
              ),
              _c("span", { staticClass: "information" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "CarRepairAndMaintainService.MechanicalRepair.SubTitle"
                    )
                  )
                ),
              ]),
            ]),
            _c(
              "div",
              [_c("diagnosis-and-testing"), _c("mechanical-repair-and-more")],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "d-none d-sm-block" },
        [
          _c("v-col", { staticClass: "col-md-11 mx-auto" }, [
            _c("h3", { staticClass: "mb-0 service-header text-none" }, [
              _vm._v(_vm._s(_vm.$t("PopularServices"))),
            ]),
            _c(
              "div",
              { staticClass: "services-container" },
              [
                _c(
                  "v-row",
                  { staticClass: "col-md-12 mx-0" },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-decoration-none text--black",
                            attrs: {
                              to: {
                                name: "AutoEstimate",
                                params: { categoryId: "01" },
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              staticClass: "mx-auto",
                              attrs: {
                                "max-width": 124,
                                src: require("../assets/icons/otherServices/ic-oil-change.webp"),
                              },
                            }),
                            _c(
                              "p",
                              {
                                class: [
                                  "text-center",
                                  "mt-3",
                                  "service-description",
                                ],
                              },
                              [_vm._v(" Oil Change ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.mechanicalRepairServices,
                      function (service, index) {
                        return _c(
                          "v-col",
                          { key: index, class: ["col-md-3", "col-sm-4"] },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "text-decoration-none text--black",
                                attrs: {
                                  to: {
                                    name: "AutoEstimate",
                                    params: {
                                      categoryId: 0,
                                      serviceName: _vm.codePrettyUrl(
                                        service.params.serviceName
                                      ),
                                    },
                                  },
                                },
                              },
                              [
                                _c("v-img", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    "max-width": 124,
                                    src: require("../assets/icons/mechanicalRepair/" +
                                      service.pathImage),
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    class: [
                                      "text-center",
                                      "mt-3",
                                      "service-description",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(service.description) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "d-none d-sm-block" },
        [
          _c("v-col", { staticClass: "col-md-11 mx-auto" }, [
            _c("h3", { staticClass: "mb-0 service-header" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("CarRepairAndMaintainService.OtherServices.Title")
                  ) +
                  " "
              ),
            ]),
            _c("span", { staticClass: "information" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("CarRepairAndMaintainService.OtherServices.SubTitle")
                )
              ),
            ]),
            _c(
              "div",
              { staticClass: "services-container" },
              [
                _c(
                  "v-row",
                  { staticClass: "col-md-12 mx-0" },
                  _vm._l(_vm.services, function (service, index) {
                    return _c(
                      "v-col",
                      { key: index, class: ["col-md-3", "col-sm-4"] },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-decoration-none text--black",
                            attrs: {
                              event: !_vm.comingSoonServices.includes(
                                service.description
                              )
                                ? "click"
                                : "",
                              to: {
                                name: service.goTo,
                                params: service.params,
                              },
                            },
                          },
                          [
                            _c("v-img", {
                              staticClass: "mx-auto",
                              attrs: {
                                "max-width": 124,
                                src: require("../assets/icons/otherServices/" +
                                  service.pathImage),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.comingSoonServices.includes(
                                    service.description
                                  )
                                    ? (_vm.comingSoonDialog = true)
                                    : null
                                },
                              },
                            }),
                            _c(
                              "p",
                              {
                                class: [
                                  "text-center",
                                  "mt-3",
                                  "service-description",
                                ],
                              },
                              [_vm._v(" " + _vm._s(service.description) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("mobile-service-mobile"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "384" },
          model: {
            value: _vm.comingSoonDialog,
            callback: function ($$v) {
              _vm.comingSoonDialog = $$v
            },
            expression: "comingSoonDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-2 pb-8 px-6" },
            [
              _c(
                "v-card-title",
                { staticClass: "w-100 justify-space-between px-0" },
                [
                  _c("p", { staticClass: "text-center font-weight-regular" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Information")) + " "),
                  ]),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.comingSoonDialog = false
                        },
                      },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "px-0 py-0 title black--text font-weight-medium",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ComingSoon")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }